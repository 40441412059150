.mdl-data-table{
	.ng-table-header{
		@extend .w700, .t-left;
	}
}

.no-pagination{
	.ng-table-pager{
		display: none;
	}
}

.responsive-table{
	$breakpoint-alpha: $grid-tablet-breakpoint - 1;

	th{
		display: none;
	}

	td{

		@media (max-width: $breakpoint-alpha + 1){
			display: block;
			height: auto;
			line-height: 1.1;
			padding-top: 5px;
			padding-bottom: 5px;
			border-top: none;
			border-bottom: none;

			&:first-child{
				padding-top: 18px;
				padding-left: 18px;
				border-top: 1px solid rgba(0, 0, 0, 0.04);
			}

			&:last-child{
				padding-bottom: 18px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.04);
			}

			&:not(.row-action):not(.no-data-th){
				&:before{
					content: attr(data-th)": ";
					font-weight:bold;
					display: block;
					font-size: 12px;
					line-height: 1;
				}
			}

			&.row-action{
				text-align: right;
				position: relative;
				margin-top: -37px;
			}
		}

		&:before{
			@media (min-width: $breakpoint-alpha) {
				display: none;
			}
		}
	}
	th, td{
		text-align: left;
		@media (min-width: $breakpoint-alpha){
			display: table-cell;
		}
	}
}