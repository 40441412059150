.ml-dialog{
	@extend .flex;
	align-items: center;
	justify-content: center;

	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9;

	visibility: hidden;
	opacity: 0;

	transition: visibility 0s, opacity .5s linear;

	&.active{
		visibility: visible;
		opacity: 1;

		.ml-dialog__wrap{
			opacity: 1;
			top: 0;
		}

		&.dismiss{
			opacity: 0;
			
			.ml-dialog__wrap{
				top: 50px;
				opacity: 0;
			}
		}
	}

	&:before{
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: rgba(0,0,0,0.7);
	}

	.ml-dialog__wrap{
		@extend .mdl-card, .p-20;
		z-index: 2;
		width: auto;
		max-width: 350px;
		min-height: 50px;
		height: auto;
		position: relative;
		top: 50px;
		opacity: 0;

		transition: all .5s;

		.ml-dialog__title{
			@extend .mdl-card__title, .p-0, .mt-0;
		}

		.ml-dialog__content{
			@extend .mdl-card__content, .flex !optional;
			align-items: center;
			justify-content: center;

			.ml-dialog__description{
				@extend .mb-0, .ml-20, .f13, .w400;
			}
		}
	}
}

/*Custom*/
#companies-dialog{
	min-height: 400px;
	max-height: 400px;
	max-width: 350px;
	width: auto;
	overflow: hidden;

	.mdl-dialog__title{
		box-sizing: border-box;
	}

	.mdl-dialog__content{
		box-sizing: border-box;
		height: 100%;

		.mdl-list{
			box-sizing: border-box;
			height: 100%;
			max-height: 250px;
			overflow-y: scroll;

			.mdl-list__item{
				&:hover{
					background-color: rgba(0,0,0,0.1);
				}
			}
		}
	}
}