.select2-container {
  border-radius: 0;
  padding-left: 0;
  padding: 5px 0px;
  line-height: 1.5;
  cursor: pointer;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: inset 0 -1px 0 #DDD;
}

.ui-select-choices-row {
  padding: 10px 15px;
}

.select2-container .select2-choice {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.select2-container.select2-drop-above .select2-choice {
  background: none;
  border: none;
  border-radius: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.select2-drop {
  border: none;
  z-index: 99999;
  margin-top: -30px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.select2-drop.select2-drop-above {
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.select2-container .select2-choice .select2-arrow {
  background: none;
  border: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.select2-container .select2-choice div {
  background: none;
  border: none;
  border-radius: 0;
  position: static;
  left: auto;
  right: auto;
  height: auto;
  width: 18px;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.select2-search input {
  box-shadow: none;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  box-shadow: none;
}

.select2-dropdown-open .select2-choice {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.select2-results {
  margin: 0;
  padding: 0;
  max-height: 250px;
}

.select2-results .select2-result-label {
  padding: 0;
}

.select2-results .select2-highlighted {
  background: #2196F3;
}

.select2-container-multi .select2-choices {
  background: none;
  border: none;
}

.select2-container-multi.select2-container-active .select2-choices {
  border: none;
  box-shadow: none;
}

.select2-container-multi .select2-choices .select2-search-choice {
  background-color: #F9F9F9;
  background-image: none;
  border: none;
  color: inherit;
  padding: 10px 20px 10px 35px;
  margin: 0 0 5px 5px;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  box-shadow: none;
}

.select2-search-choice-close {
  -webkit-transition: none !important;
  transition: none !important;
  background: none;
  color: #2196f3;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.select2-search-choice-close:after {
  content: "close";
}

.select2-search-choice-close:hover {
  color: #90caf9;
}

.select2-display-none {
  display: none;
}