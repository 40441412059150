#home-banner{
	.advertising{
		width: 140px;
		height: 140px;
		position: absolute;
		box-sizing: border-box;
		overflow: hidden;
		top: 50%;
		margin-top: -70px;
		text-align: center;

		a{
			display: block;
			width: 100%;
		}

		img{
			width: 100%;
			object-fit: cover;
		}

		&.left-side{
			left: 1rem;
		}

		&.right-side{
			right: 1rem;
		}

		@media (max-width: 1024px) {
			margin-top: 0;
			top: 0;
			position: relative;
			margin-left: 1rem;
			margin-right: 1rem;
			display: inline-block;

			&.left-side{
				left: 0;
			}

			&.right-side{
				right: 0;
			}
		}
	}
}

.advertising{
	@media (max-width: 1024px) {
		text-align: center;
	}

	&.middle-left{
		position: absolute;
		top: 10px;
		left: 5px;
		width: 160px;
		height: auto;
		min-height: 160px;

		img{
			width: 100%;
		}

		@media (max-width: 1024px) {
			position: relative;
			display: inline-block;
			margin-left: 0.5rem;
			margin-right: 0.5rem;
			left: 0;
			margin-bottom: 1rem;
			vertical-align: top;
		}
	}

	&.middle-right{
		position: absolute;
		top: 10px;
		right: 5px;
		width: 160px;
		height: auto;
		min-height: 160px;

		img{
			width: 100%;
		}

		@media (max-width: 1024px) {
			position: relative;
			display: inline-block;
			margin-left: 0.5rem;
			margin-right: 0.5rem;
			left: 0;
			margin-bottom: 1rem;
			vertical-align: top;
		}
	}

	&.boxes{
		margin: 0 3px 5px 3px;
		width: 100%;
		box-sizing: border-box;
		clear: both;
		
		.advertising--content{
			display: inline-block;
			border: none;
			outline: none;
			float: left;
			margin: 0 2.5px;
			width: calc(25% - 5px);

			&.advertising--content{
				display: inline-block;
			}

			@media (max-width: 479px) {
				width: calc(50% - 5px);
				margin-bottom: 5px;
			}

			img{
				width: 100%;
			}
		}
	}
}

.advertising--content{
	display: block;
	margin-bottom: 0.5rem;
	&.boxes{
		margin: 0 3px 5px 3px;
		width: 100%;
		box-sizing: border-box;
		clear: both;

		a{
			display: inline-block;
			border: none;
			outline: none;
			float: left;
			margin: 0 2.5px;
			width: calc(25% - 5px);

			&.advertising--content{
				display: inline-block;
			}

			@media (max-width: 479px) {
				width: calc(50% - 5px);
				margin-bottom: 5px;
			}

			img{
				width: 100%;
			}
		}
	}
}

.adsense-box{
	display: inline-block;
	overflow: hidden;
}