.vertical-menu{
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	ul{
		list-style: none;
		padding-left: 0;
	}
	li{
		list-style: none;
		a{
			display: inline-block;
			padding: 0.5rem;
			text-decoration: none;
			color: $theme-color;
			font-size: 1rem;
			line-height: 28px;
			width: 100%;
			box-sizing: border-box;

			&:hover{
				background-color: #3F51B5;
				color: $light;

				i{
					color: $light;
				}
			}

			i{
				height: 28px;
				width: 28px;
				line-height: 28px;
				font-size: 1.1rem;
				vertical-align: top;
				text-align: center;
				border-radius: 50%;
				margin-right: 0.5rem;
				background-color: $theme-color;
				color: $light;
			}
		}
	}

	&>li{
		@extend .mdl-shadow--2dp;
		background-color: $light;
		position: relative;
		&.dropdown-item{
			position: relative;

			a,button{
				i.toggle-indicator{
					position: absolute;
					top: 0.5rem;
					right: 0;
					height: 28px;
					line-height: 28px;
					color: $theme-color;
					cursor: pointer;
					background-color: transparent;
					font-size: 1.5rem;
					transition: all 0.2s linear;
				}

				&:hover{
					i.toggle-indicator{
						color: $light;
					}
				}
			}
			
			ul.dropdown{
				visibility: hidden;
				height: 0;
				opacity: 0;
				background-color: #F5F5F5;
				transition: all 0.3s linear;
				overflow: hidden;

				li{
					a,button{
						background-color: transparent;
						border: none;
						font-size: 13px;
						font-weight: bold;
						text-align: left;
						display: block;
						padding: 0.5rem 0;
						width: 100%;
						display: inline-block;
						padding-left: 2rem;
						box-sizing: border-box;
						color: #3949AB;

						&:hover{
							//color: $light;
							cursor: pointer;
						}
					}
				}
			}

			&.expanded{
				a{
					i.toggle-indicator{
						transform: rotate(180deg);
					}
				}
				ul.dropdown{
					visibility: visible;
					height: auto;
					opacity: 1;
				}
			}
		}
	}
}