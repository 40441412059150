/*Validation (Parsley)*/
.parsley-errors-list{
	@extend .pl-0;
	@extend .mb-0;

	li{
		@extend .mt-0;
		list-style: none;
		@extend .mdl-textfield__error;
	}
}

form[novalidate]{
	.mdl-textfield{
		&.is-invalid{
			&:not(.ng-touched){
				.mdl-textfield__input{
					border-bottom: 1px solid $input-text-bottom-border-color
				}
				.mdl-textfield__label{
					color: $input-text-label-color;
					font-size: $input-text-font-size;

  					&:after{
  						background-color: $input-text-highlight-color;
  					}
				}

				&.is-focused,
				&.is-dirty,
				&.has-placeholder{
					.mdl-textfield__label{
						color: $input-text-highlight-color;
						font-size : $input-text-floating-label-fontsize;
					}
				}
			}
		}

		&.with-mask{
			@extend .is-dirty;
		}
	}
}

/*Default field*/

input[type=date],
input[type=datetime-local],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
textarea{

	&.input-field--default{
		height: 2.3rem;
		font-size: .9rem;
		margin: .3rem 0;
		padding-left: .5rem;
		padding-right: .5rem;
		box-sizing: border-box;
		border: 1px solid #e0e0e0;
		font-weight: 500;
		background-color: #fff;
		border-radius: 0;
		outline: none;
		box-shadow: none;
		transition: all .3s;
		width: 100%;
		font-family: "Roboto", sans-serif;
		box-shadow: inset 0 -1px 0 #DDD;

		transition: box-shadow, border-bottom .5s linear;

		&:focus{
			box-shadow: 0 1px 0 0 $theme-color;
			border-bottom-color: $theme-color;
		}
	}
}

textarea{
	min-height: 70px;
	padding-top: .5rem;
	padding-bottom: .5rem;
}

.ui-select-container{
	border: 1px solid #e0e0e0;
	margin: .3rem 0;
	padding-left: .5rem;
	padding-right: .5rem;
}