$iinit: 0;
$iuntil: 80;
@while $iinit <= $iuntil {
	.m-#{$iinit}{margin: #{$iinit}px;}
	.mt-#{$iinit}{margin-top: #{$iinit}px;}
	.mr-#{$iinit}{margin-right: #{$iinit}px;}
	.mb-#{$iinit}{margin-bottom: #{$iinit}px;}
	.ml-#{$iinit}{margin-left: #{$iinit}px;}
	.mv-#{$iinit}{margin: #{$iinit}px 0;}
	.mh-#{$iinit}{margin: 0 #{$iinit}px;}

	.p-#{$iinit}{padding: #{$iinit}px;}
	.pt-#{$iinit}{padding-top: #{$iinit}px;}
	.pr-#{$iinit}{padding-right: #{$iinit}px;}
	.pb-#{$iinit}{padding-bottom: #{$iinit}px;}
	.pl-#{$iinit}{padding-left: #{$iinit}px;}
	.pv-#{$iinit}{padding: #{$iinit}px 0;}
	.ph-#{$iinit}{padding: 0 #{$iinit}px;}

	$iinit: $iinit + 5;
}

.mt-only { margin-left: 0 !important; margin-right: 0 !important; margin-bottom: 0 !important; }
.mb-only { margin-left: 0 !important; margin-right: 0 !important; margin-top: 0 !important; }
.ml-only { margin-right: 0 !important; margin-top: 0 !important; margin-bottom: 0 !important; }
.mr-only { margin-left: 0 !important; margin-top: 0 !important; margin-bottom: 0 !important; }
.m-auto { margin-left: auto; margin-right: auto; }

.pt-only { margin-left: 0 !important; margin-right: 0 !important; margin-bottom: 0 !important; }
.pb-only { margin-left: 0 !important; margin-right: 0 !important; margin-top: 0 !important; }
.pl-only { margin-right: 0 !important; margin-top: 0 !important; margin-bottom: 0 !important; }
.pr-only { margin-left: 0 !important; margin-top: 0 !important; margin-bottom: 0 !important; }
.p-auto { margin-left: auto; margin-right: auto; }

/* border helpers */
.no-border { border: none !important; }
.no-border-t { border-top: none !important; }
.no-border-r { border-right: none !important; }
.no-border-b { border-bottom: none !important; }
.no-border-l { border-left: none !important; }
.no-border-lr, .no-border-rl { border-left: none !important; border-right: none !important; }
.no-border-tb, .no-border-bt { border-top: none !important; border-bottom: none !important; }
.no-border-tl, .no-border-lt { border-top: none !important; border-left: none !important; }
.no-border-tr, .no-border-rt { border-top: none !important; border-right: none !important; }
.no-border-bl, .no-border-lb { border-bottom: none !important; border-left: none !important; }
.no-border-br, .no-border-rb { border-bottom: none !important; border-right: none !important; }
.border-dashed { border-style: dashed !important; }
.border-dotted { border-style: dashed !important; }
.border-black { border-color: black; }
.border-light-grey { border-color: #e5e5e5; }
.border-medium-grey { border-color: #cccccc; }
.border-grey { border-color: #999999; }
.border-dark-grey { border-color: #222222; }
.border-white, .border-fff { border-color: white; }

/* background colors */
.bg-black { background-color: black; }
.bg-light-grey { background-color: #e5e5e5; }
.bg-medium-grey { background-color: #cccccc; }
.bg-grey { background-color: #999999; }
.bg-dark-grey { background-color: #222222; }
.bg-white, .bg-fff { background-color: white; }
.bg-none, .no-bg { background: none; background-image: none; background-color: transparent; }

.bg-facebook { background-color: #47639E; }
.bg-twitter { background-color: #02A8F3; }

/* font stuff */
.lh-1 { line-height: 1 !important; }
.lh-13 { line-height: 1.3 !important; }
.lh-15 { line-height: 1.5 !important; }
.bold, .strong { font-weight: bold; }
.no-bold { font-weight: normal; }
.italic, .em { font-style: italic; }
.strike { text-decoration: line-through; }
.underline { text-decoration: underline; }
.normal { font-weight: normal; font-style: normal; }
.sans-serif { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
.serif { font-family: Georgia, "Times New Romain", serif; }
.uppercase { text-transform: uppercase; }
.t-right { text-align: right }
.t-center { text-align: center }
.t-left { text-align: left }

.mw400 { max-width:400px; }
.mw500 { max-width:500px; }
.mw600 { max-width:600px; }


.w100 { font-weight: 100;}
.w200 { font-weight: 200;}
.w300 { font-weight: 300;}
.w400 { font-weight: 400;}
.w500 { font-weight: 500;}
.w600 { font-weight: 600;}
.w700 { font-weight: 700;}
.w800 { font-weight: 800;}
.w900 { font-weight: 900;}


.f9 { font-size:9px;}
.f10 { font-size:10px;}
.f11 { font-size:11px;}
.f12 { font-size:12px;}
.f13 { font-size:13px;}
.f14 { font-size:14px;}
.f15 { font-size:15px;}
.f16 { font-size:16px;}
.f17 { font-size:17px;}
.f18 { font-size:18px; line-height:30px; }
.f19 { font-size:19px;}
.f20 { font-size:20px;}
.f30 { font-size:30px;}
.f40 { font-size:40px;}
.f50 { font-size:50px;}
.f60 { font-size:60px;}
.f70 { font-size:60px;}
.f80 { font-size:60px;}
.f90 { font-size:60px;}

.size-small { font-size: 75% !important; }
.size-normal { font-size: 100% !important; }
.size-medium { font-size: 125% !important; }
.size-big, .size-large { font-size: 150% !important; }
.size-huge { font-size: 200% !important; }
.inherit { font: inherit; }
.no-wrap { white-space: nowrap; }
.auto-cell-size { white-space: nowrap; width:1%; }
.ls-0 { letter-spacing: -3px; margin-left: 10px; margin-right: 10px; white-space: nowrap; }
.no-ul, .no-ul:hover, .no-ul a, .no-ul a:hover { text-decoration: none; }
/* can go on <a> or on its parent */


/* general helpers */
.color-inherit { color: inherit; }
.clear { clear: both; }
.clear:after { display: table; content: " "; clear: both; }
.f-left { float: left; }
.f-right { float: right; }
.f-none { float: none; }
.block { display: block !important; }
.inline { display: inline !important; }
.table { display: table; }
.in-block { display: inline-block !important; *display: inline !important; zoom: 1; }
.d-none, .hide, .hidden { display: none !important; }
.rel, .relative { position: relative !important; }
.abs, .absolute { position: absolute; }
.static { position: static; }
.fixed { position: fixed; }
.t-0 { top: 0; }
.b-0 { bottom: 0; }
.l-0 { left: 0; }
.r-0 { right: 0; }
.tb-0 { top: 0; bottom: 0; }
.lr-0 { left: 0; right: 0; }
.v-mid, .v-m { vertical-align: middle !important; }
.v-top, .v-t { vertical-align: top !important; }
.v-bottom, .v-b { vertical-align: bottom !important; }
.v-super { vertical-align: super !important; }
.full-input input, .full-input select, .full-input textarea { width: 100%; }
.normal input, .normal select, .normal textarea, .full-input input[type=checkbox], .full-input input[type=radio] { width: auto; }
.no-shadow { box-shadow: none !important; }
.no-border-radius { border-radius: 0 !important; }
.overflow-x-scroll { max-width: 100%; overflow-x: auto; }
.overflow-no { overflow: hidden; }
.overflow-auto { overflow: auto; }
.overflow-scroll { overflow: scroll; }
.pointer { cursor: pointer; }

.fullwidth { width: 100%; }
.fullheight { height: 100%; }
.nolist { list-style: none;  }

.text-shadow { text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }
.radius3 {border-radius: 3px; }
.radius5 {border-radius: 5px; }
.radius10 {border-radius: 10px; }

.text-rgb-2 { color: rgba(0, 0, 0, 0.2); }
.text-rgb-3 { color: rgba(0, 0, 0, 0.3); }
.text-rgb-4 { color: rgba(0, 0, 0, 0.4); }
.text-rgb-5 { color: rgba(0, 0, 0, 0.5); }

.mh-100 { min-height: 100px; }
.mh-200 { min-height: 200px; }
.mh-300 { min-height: 300px; }


.margin-auto {
    margin: 0% auto;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;

}
.flex1 {
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    flex: 1;

    height: 100%;
    width: 100%;
}
.flexrow {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    -o-flex-direction: row;
    flex-direction: row;
}
.flex-right {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    -o-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.flex-centered{
  @extend .flex;
  align-items: center;
  justify-content: center;
}


textarea { resize:both; }
textarea.vertical { resize:vertical; }
textarea.horizontal { resize:vertical; }
textarea.noresize { resize:none; }




@media (max-width: 479px) {
  .hide-mobile {
    display:none;
  }
}

@media (min-width: 480px) and (max-width: 839px) {
  .hide-tablet {
    display:none;
  }
  .pr-10-tablet {
    padding-right: 10px;
  }
  .pl-10-tablet {
    padding-left: 10px;
  }
}

@media (max-width: 839px) {
  .hide-from-tablet {
    display:none;
  }
  .p-20--small {
    padding: 20px;
  }
}

.t-overflow-ellipsis{
  text-overflow: ellipsis;
}

.circle-image{
  border-radius: 50%;
}

.break-word{
  word-break: break-all;
}

.t-keep-all{
  word-break: keep-all;
}

.dark-hover{
  &:hover{
    background-color: rgba(0,0,0,0.1);
  }
}

.no-outline{
  outline: none;
}

.bg-cover{ background-size: cover; }
.bg-contain{ background-size: contain; }
.bg-centered{ background-position: center center; background-repeat: no-repeat; }

.bbox{
  box-sizing: border-box;
}