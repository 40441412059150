/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Material Design Lite */

// Variables and mixins
@import "variables";
@import "mixins";

// Resets and dependencies
@import "resets/resets";
@import "typography/typography";

// Components
@import "palette/palette";
@import "ripple/ripple";
@import "animation/animation";
@import "badge/badge";
@import "button/button";
@import "card/card";
@import "checkbox/checkbox";
@import "data-table/data-table";
@import "dialog/dialog";
@import "footer/mega_footer";
@import "footer/mini_footer";
@import "icon-toggle/icon-toggle";
@import "list/list";
@import "menu/menu";
@import "progress/progress";
@import "layout/layout";
@import "radio/radio";
@import "slider/slider";
@import "snackbar/snackbar";
@import "spinner/spinner";
@import "switch/switch";
@import "tabs/tabs";
@import "textfield/textfield";
@import "tooltip/tooltip";
@import "shadow/shadow";
@import "grid/grid";
