.mdl-card{
	&.expansible{
		min-height: 42px;
		.mdl-card__title{
			position: relative;
			cursor: pointer;
			.expand-text-button{
				position: absolute;
				top: 50%;
				right: 1rem;
				margin-top: -14px;
				cursor: pointer;

				transition: transform .5s;
			}
		}

		.mdl-card__supporting-text{
			height: 0;
			min-height: 0;
			padding: 0;
			opacity: 0;
			transition: all .5s;
		}

		&.expanded{
			.expand-text-button{
				transform: rotate(180deg);
			}

			.mdl-card__supporting-text{
				height: auto;
				padding: 16px;
				opacity: 1;
			}
		}
	}

	.mdl-card__title{
		&.with-bg-image{
			height: 170px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;

			&:before{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 50%;
				background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7));
			}
		}

		.mdl-card__title-text{
			color: $light;
			position: relative;
		}
	}
}

a.mdl-card{
	text-decoration: none;
}

.mdl-card__supporting-text{
	text-decoration: none;
}