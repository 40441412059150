$light: #ffffff;
$theme-color: #283593;
$image_path: '../images';
$tab-highlight-color: $theme-color;

// bower:scss
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import "bower_components/sass-flex-mixin/_flex.scss";
@import "bower_components/material-design-lite/src/material-design-lite.scss";
// endbower

@import "_components/helper";
@import "_components/select";
@import "_components/forms";
@import "_components/dialog";
@import "_components/tables";
@import "_components/cards";
@import "_components/vertical-nav";
@import "_components/advertising";

html, body{
	height: 100%;
	width: 100%;
	font-family: "Roboto", sans-serif;
}

a{
	color: rgb(30,136,229);
}

.bloco-description{
	position: relative;
	width: 100%;
	box-sizing: border-box;
	*{
		line-height: 18px;
		font-size: 1rem;
		margin: 0;
		width: 100%;
		max-width: 300px;
	}
}

.box-shadow-animate{
	transition: box-shadow .25s;

	&:hover{
		box-shadow: 0 8px 17px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);
	}
}

.mdl-color-text--theme,
.mdl-color-text--theme:hover{
	color: $theme-color;
}
.mdl-color--theme,
.mdl-color--theme:hover{
	background-color: $theme-color;
}


.mdl-layout__header{
	background-color: $theme-color;
}

.unloaded{
	display: none !important;
}

.loaded{
	display: flex !important;
}

.mdl-layout__content{
	&.clean-page{
		margin-top: 0;
		margin-left: 0;
		width: 100%;
	}
}

.page-main{
	margin-top: -35vh;
}

.mdl-layout__drawer-button{
	outline: none;
}

.mdl-layout__drawer{
	.logo-title{
		display: block;
		background-color: $theme-color;
		max-width: 100%;
		padding: 1.5rem 1rem;
	}

	.mdl-navigation__link{
			&.button-link{
				@extend .uppercase, .bold;
				color: $theme-color;				
			}
		}
}

.mdl-layout__header-row{
	.mdl-navigation__link{
		padding: 0 12px;
		color: $light;

		&.button-link{
			@extend .uppercase, .f12, .bold;
			height: 32px;
			line-height: 32px;
			border: 1px solid $light;
			margin-right: 12px;
			border-radius: 5px;
		}
	}
}

.mdl-mega-footer--heading, .mdl-mega-footer__heading{
	border-bottom: 1px solid $light;
	width: 95%;
}

.content-grid{
	 max-width: 960px;
	 padding: 0 170px;

	@media (max-width: $layout-screen-size-threshold) {
		padding: 0;
	}
}

header{
	.mdl-layout__drawer-button{
		display: block;
		@media (min-width: $layout-screen-size-threshold + 1) {
			display: none;
		}
	}

	.mdl-layout__header-row{
		display-left: 72px;
		@media (min-width: $layout-screen-size-threshold + 1) {
			padding-left: 16px;
		}
	}
}

#home-banner{
	min-height: 350px;
	background: url('../images/banner.jpg') center center no-repeat;
	background-size: cover;
	position: relative;
	text-align: center;

	.home-banner--title{
		font-size: 4rem;
		font-weight: 700;
		line-height: 1.1;
		color: $light;
		position: relative;
		font-weight: 400;
	}

	.home-banner--subtitle{
		font-size: 3rem;
		font-weight: bold;
		line-height: 1.1;
		color: $light;
		position: relative;
		font-weight: 400;
	}

	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.4);
		z-index: 0;
	}
}

.word-separator{
	height: 34px;
	position: relative;
	text-align: center;
	margin: 5px 0;

	.word-separator-text{
		font-size: 14px;
		display: inline-block;
		padding: 8px;
		font-weight: bold;
		line-height: 1.1;
	}

	hr{
		margin: -16px auto 10px;
		width: 100%;
		border: 0;
		border-top: 1px solid #dce0e0;
	}
}

.sign-form{
	a{
		text-decoration: none;
		display: block;
		text-align: right;
		font-size: 0.9rem;
		margin: 10px 0;
	}
}

header{
	.mdl-layout__header-row{
		nav.mdl-navigation{
			a{
				margin: 0 0.5rem;
				text-align: center;
				i{
					display: block;
					line-height: 22px;
				}
				span{
					display: block;
					line-height: 22px;
				}
			}
		}
	}
}

#horizontal-menu{
	position: relative;
	z-index: 9;
	.container{
		text-align: center;
		nav{
			.menu-item{
				display: inline-block;
				position: relative;
				list-style: none;
				margin: 0;
				padding: 0;
				li{
					list-style: none;
					margin: 0;
					padding: 0;
					display: inline-block;
					position: relative;

					a{
						@extend .mdl-color-text--grey-100;
						display: inline-block;
						padding: 1rem 0.8rem;
						text-decoration: none;

						i{
							display: none;
						}

						&:hover{
							background-color: rgba(0,0,0,0.1);
						}
					}

					&.submenu{
						position: relative;

						&:hover{
							ul.dropdown-menu{
								display: block;
							}
						}
					}

					ul.dropdown-menu{
						@extend .mdl-shadow--2dp;
						display: none;
						position: absolute;
						top: 48px;
						left: 0;
						width: 200px;
						z-index: 9;
						list-style: none;
						padding: 0;
						margin: 0;
						background-color: rgb(25,118,210);
						li{
							list-style: none;
							padding: 0;
							margin: 0;
							position: relative;
							display: block;
							a{
								display: block;
								text-align: left;
								padding-top: 10px;
								padding-bottom: 10px;
								position: relative;
								color: #fff;

								&:hover{
									background-color: rgba(0,0,0,0.2);
								}
							}
						}
					}

					&.dropdown{
						&:hover,&.active{
							ul.dropdown-menu{
								display: block;
							}
						}
					}
				}
			}
		}
	}

	@media(max-width: $layout-screen-size-threshold){
		display: none;

		&.active{
			display: block;

			.container{
				nav{
					flex-wrap: nowrap;
					white-space: nowrap;
					overflow-x: auto;
					overflow-y: visible;
					.menu-item{
						a{
							min-width: 75px;
							i{
								width: 25px;
								height: 25px;
								margin: 0 auto;
								display: block;
								border: 2px solid #ffffff;
								border-radius: 50%;
								padding: 0.3rem;
							}
						}
						.dropdown{
							position: static;

							&:not(.active){
								ul.dropdown-menu{
									display: none;
								}
								
								&:hover{
									ul.dropdown-menu{
										display: none;
									}
								}
							}

							&.active{
								.dropdown-menu{
									display: block;

									&:before{
										content: "close";
										position: absolute;
										top: 50%;
										left: 0;
										margin-top: -18px;
										width: 35px;
										height: 35px;
										line-height: 35px;
										font-family: 'Material Icons';
										color: #fff;
										font-size: 28px;
										cursor: pointer;
									}

									position: absolute;
									top: 0 !important;
									left: 0;
									width: 100%;
									height: 100%;
									box-shadow: none;

									li{
										display: inline-block;
										text-align: center;

										a{
											text-align: center;
											line-height: 68px;
										}
									}
								}
							}
						}

						/*
						&.dropdown{
							&:hover{
								ul.dropdown-menu{
									display: none;
								}
							}

							&:active{
								ul.dropdown-menu{
									display: block;
								}
							}
						}
						*/
					}

					.dropdown-menu{
						top: 80px !important;
					}
				}
			}
		}
	}
}

#home-section{
	.blocos-grid{
		max-height: 320px;
		& > a.block{
			max-height: 320px;
		}
	}
	.mdl-card{
		.mdl-card__title{
			position: relative;
			min-height: 150px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;

			&:before{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 50%;
				background-image: linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0));
			}

			.mdl-card__title-text{
				position: relative;
				color: #ffffff;
			}
		}
	}
}

#clima-section{
	.clima-box{
		border: none !important;
		width: 100% !important;
		overflow: auto;

		iframe .wP17 .fondo .nomP{
			display: none !important;
		}
	}
}

.gallery{
	.gallery-item{
		border: none;
		text-decoration: none;
	}
}













/*Default field*/
/*
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
textarea{
	height: 2.3rem;
	font-size: .9rem;
	margin: .3rem 0;
	padding-left: .5rem;
	padding-right: .5rem;
	box-sizing: border-box;
	border: 1px solid #e0e0e0;
	font-weight: 500;
	background-color: #fff;
	border-radius: 0;
    outline: none;
    box-shadow: none;
	transition: all .3s;
	width: 100%;
	font-family: "Roboto", sans-serif;
	box-shadow: inset 0 -1px 0 #DDD;

	transition: box-shadow, border-bottom .5s linear;

	&:focus{
		box-shadow: 0 1px 0 0 $theme-color;
		border-bottom-color: $theme-color;
	}
}

textarea{
	min-height: 70px;
	padding-top: .5rem;
	padding-bottom: .5rem;
}
*/

.ui-select-container{
	border: 1px solid #e0e0e0;
	margin: .3rem 0;
	padding-left: .5rem;
	padding-right: .5rem;
}

.estrada-map{
	width: 100%;
	height: 500px;

	#estrada-map{
		width: 100%;
		height: 100%;
	}
}

.praia-detalhes-header{
	@extend .flex-centered;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 300px;
	position: relative;

	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.2);
	}

	.praia-detalhes-title{
		color: $light;
		position: relative;
	}
}

.service-info{
	a{
		display: block;
		text-decoration: none;
		margin-bottom: 0.5rem;
		i{
			vertical-align: top;
			margin-right: 0.5rem;
		}
	}
}

.resultados-busca{
	.busca-item{
		position: relative;
		.busca-item--image{
			position: absolute;
			left: 0;
			top: 0;
			margin-top: 1rem;
			margin-left: 1rem;
			width: 150px;
			height: 150px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;

			@media (max-width: $grid-tablet-breakpoint - 1) {
				position: relative;
				top: auto;
				left: auto;
				margin-top: 1rem;
				width: calc(100% - 80px);
				height: 100px;
				background-size: 100px 100px;
				background-position: left center;
				box-sizing: border-box;

				span{
					top: 0 !important;
					left: 0 !important;
					padding-top: 110px;
					display: block;
				}
			}
		}

		.busca-item--info{
			padding: 1rem;
			float: left;
			padding-left: 180px;

			&.no-left-padding{
				padding-left: 1rem;
			}

			&.f-none{
				float: none;
			}

			@media (max-width: $grid-tablet-breakpoint - 1) {
				padding-left: 1rem;
				float: none;
				margin-top: 20px;
			}

			.stars{
				i{
					font-size: 1.3rem;
				}
			}

			.busca-item--title{
				font-size: 1.5rem;
				font-weight: bold;
			}

			.busca-item--subtitle{
				font-size: 1.1rem;
				font-weight: normal;
				opacity: 0.9;
				margin-top: 0.3rem;
				margin-bottom: 0.3rem;
			}

			.busca-item--description{
				margin-top: 0.5rem;
				font-size: 0.9rem;

				a{
					text-decoration: none;
					line-height: 25px;

					i{
						font-size: 25px;
						line-height: 25px;
						vertical-align: top;
						margin-right: 5px;
					}
				}
			}

			.busca-item--details{
				margin-bottom: 0.5rem;
				.label{
					font-weight: bold;
					line-height: 25px;
					font-size: 14px;
					i{
						font-size: 25px;
						line-height: 25px;
						vertical-align: top;
						margin-right: 5px;
					}
				}

				.label-value{
					display: inline-block;
					font-size: 14px;
				}
			}

			@media (max-width: 600px) {
				padding-right: 70px;
			}
		}

		.busca-item--action{
			position: absolute;
			top: 0.1rem;
			right: 0.1rem;

			&.bottom{
				top: auto;
				bottom: 0rem;
				right: 0rem;

				a{
					text-decoration: none;
				}

				.external-link{
					width: 30px;
					height: 30px;
					min-width: 30px;
					line-height: 30px;
					margin-left: 0.5rem;

					img{
						width: 100%;
						display: block;
					}
				}

				.button-description{
					font-size: 12px;
				}
			}

			.mdl-button{
				width: 50px;
				height: 50px;
				color: $theme-color;

				.mdl-badge[data-badge]:after{
					background-color: #D50000;
				}
			}

			.button-description{
				display: block;
				font-size: 14px;
				font-weight: 500;
			}

			@media (max-width: 600px) {
				&.bottom{
					top: 0;
					bottom: auto;
					right: 0;

					.in-block{
						display: block !important;
						margin-bottom: 0.5rem;
						margin-right: 0;
					}
				}
			}
		}

		&.details{
			.busca-item--info{
				padding: 1rem 0;
				width: 100%;
			}

			.busca-item--action{
				&.bottom{
					bottom: -0.8rem;
					right: -0.5rem;
				}
			}

			.busca-item--action{
				@media (max-width: 600px) {
					&.bottom{
						text-align: right;
						position: relative;

						.in-block{
							display: inline-block !important;
							margin-bottom: 0.5rem;
							margin-right: 0.5rem;
						}
					}
				}
			}
		}
	}
}

#message-item-dialog{
	.mdl-dialog__content{
		max-height: 350px;
		overflow: auto;
	}
}

.hospedagem-email{
	height: 0;
	overflow: hidden;
}

@media print {
	*{
		overflow: hidden !important;
	}
}

.service-item{
	.mdl-card__title{
		&.with-image{
			min-height: 200px;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			position: relative;

			.mdl-card__title-text{
				position: relative;
				color: #fff !important;
			}

			&:before{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 40%;
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); 
			}
		}
	}
}

.print-page{
	#home-banner,
	#horizontal-menu,
	footer,
	i.material-icons,
	.busca-item--action{
		display: none;
	}

	.busca-item{
		& > div{
			padding-top: 0 !important;
		}
	}

	.busca-item--details{
		.label, .label-value{
			display: inline-block;
			line-height: 1.3;
			vertical-align: middle;
			font-size: 1rem;
		}
	}
}



/*PESQUISA GOOGLE*/
#sec-all-top #barra-topo{
	position: relative;
}

#sec-all-top #barra-topo a{
	font-size: 0.8rem;
}

#sec-all-top #barra-topo .pesquisar{
	background-color: #fff;
	display: inline-block;
	height: 25px;
	line-height: 25px;
	position: absolute;
	top: -2px;
	right: 0;
	padding-right: 25px;
	padding-left: 0.2rem;
	border-radius: 2px;
}
#sec-all-top #barra-topo .pesquisar td.gsib_b{
	display: none;
}

#sec-all-top #barra-topo .pesquisar *,
#sec-all-top #barra-topo .pesquisar *:focus{
	border-color: transparent !important;
	border: none !important;
	background-color: transparent !important;
	outline: none !important;
	border-collapse: collapse !important;
	box-shadow: none !important;
}

#sec-all-top #barra-topo .pesquisar .gsc-results-wrapper-overlay{
	background-color: #ffffff !important;
}

#sec-all-top #barra-topo .pesquisar > div{
	display: inline-block;
}
.gsc-control-cse{
	background-color: transparent !important;
	border: none !important;
	width: 200px !important;
	padding: 0 !important;
	position: relative;
	margin-right: 25px;
}

.cse .gsc-control-cse:after, .gsc-control-cse:after{
	content: "" !important;
	height: auto !important;
	visibility: visible !important;
	display: inline-block !important;
	width: 15px !important;
	height: 15px !important;
	position: absolute;
	top: -3px;
	right: -30px;
	background: url('../images/search-icon2-white.png') no-repeat center center;
	background-size: 70% 70%;
	cursor: pointer;
	padding: 0.5rem;
	background-color: trasparent;
}

.form.gsc-search-box{
	margin-bottom: 0 !important;
}
.gsc-search-box-tools .gsc-search-box .gsc-input{
	padding: 0 !important;
	position: relative;
	top: -2px;
	border-color: transparent;
	width: 1px;
}

input.gsc-input[type=text]:focus:not([readonly]){
	border: none !important;
	border-bottom: none !important;
	box-shadow: none !important;
	outline: none !important;
	border-color: transparent !important;
	background-color: transparent !important;
}

.gsib_a{
	padding: 0 !important;
}
.gsc-search-button{
	display: none;
}
.gsc-control-cse div.gsc-input-box{
	position: relative;
	top: 2px;
	border: none !important;
}

.gsc-control-cse div.gsc-input-box input{
	position: relative;
	top: 1px !important;
}

.expand-top-menu{
	width: 100%;
	text-align: center;
	position: relative;
	background-color: #3F51B5;
	top: -5px;
}

.expand-top-menu .fb-like{
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.expand-top-menu-content{
	position: relative;
	top: 15px;
	display: inline-block;
	height: 35px;
	width: 35px;
	line-height: 35px;
	border-radius: 50%;
	background-color: #3F51B5;
	cursor: pointer;
}

.expand-top-menu-content i{
	line-height: 35px;
	font-size: 1.3rem;
	color: rgba(255,255,255,0.5);
}

/*FOR TABLET*/
@media screen and (min-width: 601px) and (max-width: 992px) {
	.gsc-control-cse div.gsc-input-box{
		top: 4px;
	}
	#sec-all-top #barra-topo .pesquisar{
		background-color: transparent;
	}

	#sec-all-top #barra-topo .pesquisar .gsc-control-cse{
		visibility: hidden;
	}

	#sec-all-top #barra-topo .pesquisar input.gsc-input{
		display: none;
	}

	#sec-all-top #barra-topo .pesquisar .gsc-control-cse:after{
		background: url('../images/search-icon2-white.png') no-repeat center center;
		background-color: transparent;
		background-size: 70% 70%;
	}


	/*IF ACTIVE*/
	#sec-all-top #barra-topo .pesquisar.active{
		background-color: #fff;
		width: 100%;
	}

	#sec-all-top #barra-topo .pesquisar.active > div{
		width: 100%;
	}

	#sec-all-top #barra-topo .pesquisar.active .gsc-control-cse{
		visibility: visible;
		width: 100% !important;
	}
	#sec-all-top #barra-topo .pesquisar.active input.gsc-input{
		display: block;
	}
	#sec-all-top #barra-topo .pesquisar.active .gsc-control-cse:after{
		background: url('../images/search-icon2-white.png') no-repeat center center;
		background-size: 70% 70%;
	}
}

@media screen and (max-width: 600px) {

	#sec-all-top{
		margin-top: -235px;
	}

	#sec-all-top.active{
		margin-top: 0px;
	}

	#sec-all-top #barra-topo{
		text-align: center;
	}
	#sec-all-top #barra-topo a{
		display: block;
		font-size: 1.1rem;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid rgba(255,255,255,0.1);
		padding: 0.4rem 0;
	}

	#sec-all-top #barra-topo .pesquisar{
		margin-top: 1rem;
		position: relative !important;
		width: 100% !important;
	}

	#sec-all-top #barra-topo .pesquisar > div,
	#sec-all-top #barra-topo .pesquisar .gsc-control-cse,
	#sec-all-top #barra-topo .pesquisar .gsc-input .gsc-input-box{
		width: 100% !important;
	}
}
/*END BARRA TOPO*/

#___gcse_0{
	.gsc-control-cse, .gsc-input-box, .gsc-control-wrapper-cse{
		width: 0 !important;
	}

	&.active{
		.gsc-control-cse{
			width: 200px !important;
		}
		.gsc-input-box{
			width: 200px !important;
		}
		.gsc-control-wrapper-cse{
			width: auto !important;
		}		
	}
}

.social-list{
	li{
		display: inline-block;
		text-align: center;
		a{
			font-size: 1.5rem;
			margin-right: 0.5rem;

			&:hover{
				color: #fff;
			}
		}
	}
}

.camera-transito-image{
	display: inline-block;
	margin: 0 0.5rem;
}

.condicoes_estradas{
	.in-block{
		width: 33%;
		margin: 0 0.5rem;

		@media (max-width: 450px) {
			width: 100%;
		}

	}
	.mdl-card{
		width: 100%;
		display: inline-block;

		img{
			width: 100%;
		}
	}
}

.main-content{
	&.top-content{

	}
}

#envia-email-dialog{
	width: 100%;
	text-align: center;
	@extend .flex-centered;

	.ml-dialog__wrap{
		width: 100%;
		max-width: 600px;
		height: 100%;
		max-height: 610px;
		overflow: auto;
		margin: 0 auto;
		position: static;
	}

	form{
		display: block;
		position: relative;
		clear: both;

		.mdl-textfield{
			display: inline-block;
			width: 50%;
			float: left;

			&.fullwidth{
				width: 100%;
				float: none;
			}
		}
	}
}


body.is-ie,
body.is-opera,
body.is-safari{
	#___gcse_0{
		.gsc-control-cse{
			width: 200px !important;
		}
		.gsc-input-box{
			width: 200px !important;
		}
		.gsc-control-wrapper-cse{
			width: auto !important;
		}		
	}

	#envia-email-dialog{
		&:before{
			display: none !important;
			z-index: -1 !important;
		}
	}
}

body.is-safari,
body.os-ios{
	#envia-email-dialog{
		&:before{
			display: none;
		}
	}
	@media (max-width: 450px) {
		#envia-email-dialog,
		.ml-dialog{
			&:before{
				display: none;
			}
		}
	}
}

#envia-email-dialog{
	z-index: 99999 !important;
}


.pika-single.is-bound{
	z-index: 999999 !important;
}

.hospedagens-checkboxes{
  .hospedagem-checkbox-item{
  	position: relative;
  	text-align: left;
  	min-height: 35px;
  	vertical-align: top;

    img{
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
      position: absolute;
      top: -6px;
      left: -7px;
      opacity: 1;
      z-index: 2;
    }

    .mdl-checkbox__label{
    	line-height: 13px !important;
    	font-size: 14px;
    	padding-left: 5px;
    	vertical-align: top;
    }

    &:hover{
    	cursor: pointer;
    	img{
    		opacity: 0.8;
    	}
    }

    &.active{
    	img{
    		opacity: 0.1;
    	}
    }

    input{
    	line-height: 30px;
    	text-align: center;
    	position: relative;
    	right: 3px;
    }

    .hospedagem-checkbox-item-label{
    	font-size: 13px;
    	line-height: 1;
    	padding-left: 35px;
    	position: absolute;
    	top: 0;
    	left: 0;
    }
  }
}

.faca-sua-reserva-agora{
	background-color: #283593;
	text-align: center;
	position: relative;
	z-index: 1;

	h3{
		padding: 0;
		margin: 0;
		a{
			text-decoration: none;
			font-size: 2rem;
			font-weight: 400;
			color: #fff;
			padding: 1rem 2rem;
			display: block;
		}
	}
}

#hospedagem-detalhes-page{
	&.has-booking-frame{
		.advertising.middle-left,
		.advertising.middle-right{
			top: 75px;
		}
	}
}